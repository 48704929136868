import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { ThemeProvider } from "@/providers/ThemeProvider";

import "@/styles/globals.css";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  return (
    <main>
      <ThemeProvider>
        <GoogleAnalytics trackPageViews />
        <SessionProvider session={session}>
          <Component {...pageProps} />
        </SessionProvider>
      </ThemeProvider>
    </main>
  );
}
