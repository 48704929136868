import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {},
  container: {
    backgroundColor: "#fff",
    border: "2px solid",
    borderColor: "gray.800",
    borderRadius: "8px",
    boxShadow: "-4px 5px 0px 2px #222222",
    marginY: "4",
  },
  button: {
    fontSize: "lg",
    fontWeight: "bold",
    paddingY: "3",
    paddingX: "5",
  },
  panel: {
    lineHeight: "2",
    paddingX: "5",
    paddingBottom: "3",
  },
  icon: {
    width: "2em",
    height: "2em",
  },
});

export const accordionStyle = defineMultiStyleConfig({
  baseStyle,
});
