import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const wordArt = defineStyle({
  fontFamily: "eds-market-bold-slant, system-ui, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "84px",
  lineHeight: "1",
  textAlign: "center",
  width: "100%",

  background: "linear-gradient(180deg, #ffce1b 0%, #ff9afa 100%)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  "background-clip": "text",
  "text-fill-color": "transparent",
  transform: "rotate(-6.41deg)",
  "-webkit-text-stroke": "2.5px",
});

const wordArtShadow = defineStyle({
  fontFamily: "eds-market-bold-slant, system-ui, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "84px",
  lineHeight: "1",
  textAlign: "center",
  width: "100%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) rotate(-6.41deg)",
  textShadow: "-5px 5px 0px #222222",
});

export const headingTheme = defineStyleConfig({
  variants: { wordArt, wordArtShadow },
});
