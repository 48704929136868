import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  badge: {},
  container: {},
  excessLabel: {
    ml: "2",
    color: "white",
    fontSize: "xs",
    fontWeight: "bold",
    background: "purple.400",
  },
  group: {},
});

export const avatarStyle = defineMultiStyleConfig({
  baseStyle,
});
