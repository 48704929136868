import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const hightlightVariant = defineStyle(() => {
  return {
    textDecoration: "none",
    fontWeight: "normal",
    background: "rgba(255, 206, 27, 0.35)",
    borderRadius: "4",
    padding: "1px 3px",

    _hover: {
      background: "rgba(255, 206, 27, 0.65)",
      textDecoration: "none",
    },
  };
});

export const linkTheme = defineStyleConfig({
  variants: { highlight: hightlightVariant },
});
