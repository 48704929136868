import React from "react";
import {
  ChakraProvider,
  extendTheme,
  type ThemeConfig,
} from "@chakra-ui/react";
import { accordionStyle } from "./AccordionTheme";
import { avatarStyle } from "./AvatarTheme";
import { buttonTheme } from "./ButtonTheme";
import { checkboxTheme } from "./CheckboxTheme";
import { inputTheme } from "./InputTheme";
import { linkTheme } from "./LinkTheme";
import { textAreaTheme } from "./TextAreaTheme";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";
import { headingTheme } from "./Heading";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// Extend the theme to include custom colors, fonts, etc
export const theme = extendTheme({
  colors: {
    gray: {
      600: "#444",
      700: "#333",
      800: "#222",
    },
    purple: {
      100: "#E2CCFF",
      400: "#9441FF",
      600: "#6924C2",
      800: "#4C1D89",
    },
    yellow: {
      400: "#FFCE1B",
    },
  },
  fonts: {
    body: `'Poppins', system-ui, sans-serif`,
    heading: `'Poppins', system-ui, sans-serif`,
  },
  styles: {
    global: {
      body: {
        lineHeight: "var(--chakra-lineHeights-taller)",
      },
      p: {
        color: "gray.600",
        // lineHeight: "200%",
      },
      ".user-mention": {
        textDecoration: "none",
        fontWeight: "normal",
        background: "rgba(255, 206, 27, 0.35)",
        borderRadius: "4",
        padding: "1px 3px",

        _hover: {
          background: "rgba(255, 206, 27, 0.65)",
          textDecoration: "none",
        },
      },
    },
  },
  components: {
    Accordion: accordionStyle,
    Avatar: avatarStyle,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Heading: headingTheme,
    Input: inputTheme,
    Link: linkTheme,
    Textarea: textAreaTheme,
  },
  config,
});

export const ThemeProvider: React.FC<React.PropsWithChildren> = (props) => {
  return <ChakraProvider theme={theme} {...props} />;
};
