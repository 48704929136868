var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"MeM4PufZ9YRURd_BMu_GV"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_ENV = process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV;
const SENTRY_TRACE_SAMPLE_RATE = process.env.SENTRY_TRACE_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE;

Sentry.init({
  dsn: SENTRY_DSN || 'https://6bac3aa2cdb04eaf8b556985e9449417@o33797.ingest.sentry.io/4504888579719168',
  sampleRate: SENTRY_TRACE_SAMPLE_RATE ? parseFloat(SENTRY_TRACE_SAMPLE_RATE) : 1.0,
  environment: APP_ENV,
});
