import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const outline = defineStyle({
  border: "2px solid",
  borderColor: "#C5C5C5",
  paddingTop: "3",
  paddingBottom: "3",

  _hover: {
    border: "2px solid",
    borderColor: "gray.800",
  },

  _focus: {
    border: "2px solid",
    borderColor: "gray.800",
    boxShadow: "-3px 3px 0px #FFCE1B",
  },
});

export const textAreaTheme = defineStyleConfig({
  variants: { outline },
});
