import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

export const buttonTheme = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    border: "2px solid",
    borderColor: "gray.800",
    boxShadow: "-3px 3px 0px #222222",
  },
  sizes: {
    md: {
      fontSize: "sm",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
  },
});
