import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({});

const variantHidden = definePartsStyle({
  control: defineStyle({
    display: "none",
  }),
  container: defineStyle({
    borderRadius: "8",
    border: "2px solid",
    borderColor: "gray.800",
    padding: "1",
    transitionProperty: "var(--chakra-transition-property-common)",
    transitionDuration: "var(--chakra-transition-duration-normal)",

    _hover: {
      boxShadow: "-5px 5px 0px #222222",
    },

    _checked: {
      boxShadow: "-5px 5px 0px #FFCE1B",
    },
  }),
});

const variants = {
  hidden: variantHidden,
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
});
