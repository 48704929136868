import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { inputAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  field: {
    border: "2px solid",
    borderColor: "#C5C5C5",
    height: "12",
    paddingTop: "2",
    paddingBottom: "2",

    _hover: {
      border: "2px solid",
      borderColor: "gray.800",
    },

    _focus: {
      border: "2px solid",
      borderColor: "gray.800",
      boxShadow: "-3px 3px 0px #FFCE1B",
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: { outline },
});
